//Config File of PW Application
export const appConfig = {
  mainDomain: "https://api-dev.mystylist.in",
};

export const adminMiddlePoints = "https://api-dev.mystylist.in";

export const adminendPoints = {
  AdminLogin: `admin/adminLogin`,
  AdminRegister: `admin/adminRegister`,
  AdminExpertList: `admin/allExpertList`,
  AdminUpdatePermission: `admin/updatePermission`,

  OnboardingUpdateBussformation: `user/updateBussformation`,
  OnboardingUpdateProInformation: `user/updateProInformation`,
  OnboardingSaveAmenities: `user/UpdateAmenities`,
  OnboardingUpdateExpertServices: `user/UpdateExpertServices`,
  onboardingAssignService: `demo/onboardingAssignService`,
  onboardingExpertServiceSubServices: `demo/getAllServiceSubServices`,
  OnboardingUpdateFeaturedImage: `user/updateFeaturedProfileImage`,
  OnboardingDeletedServiceImage: `demo/onboardingDeletServiceImage`,

  AdminAddCategory: `category/addcategory`,
  AdminCategoryList: `category/getAllCategories`,
  AdminCategoryDetails: `category/viewCategory`,
  AdminEditCategory: `category/editcategory`,

  AdminGetAllCategories: `category/getAllCategoriesForMobile`,

  AdminGetAllExperts: `user/getAllExperts`,
  AdminAddExpert: `user/AddExpert`,
  AdminExpertRegister: `user/expertRegisterAdmin`,
  AdminUploadExpertGallery: `user/uploadGallery`,
  AdminExpertUserDetails: `user/getUserDetails`,
  AdminEditExpert: `user/EditExpert`,
  AdminUpdateUserInformation: `user/UpdateUserInformation`,
  AdminUpdateExpertInformation: `user/UpdateExpertInformation`,
  AdminUpdateAssignServices: `user/UpdateAssignServices`,
  AdminUpdateShopInformation: `user/UpdateShopInformation`,
  AdminUpdateAmenities: `user/UpdateAmenities`,
  AdminGetUsersByCategory: `user/getUsersByCategory`,
  AdminGetAllUsers: `user/getAllUsers`,
  AdminGetUsersByServiceAndSubService: `user/getUsersByServiceAndSubService`,
  AdminUpdateStatusExpert: `user/updateExpertStatus`,
  AdminGetAllExpertReview: `review/getAllExpertReview`,
  AdminUpdateProfileSortOrder: `user/updateProfileSortOrder`,
  AdminUpdateGallerySortOrder: `user/updateGallerySortOrder`,

  AdminDeleteUserProfileImage: `user/DeleteUserProfileImage`,
  AdminDeleteUserWorkingImage: `user/DeleteUserWorkingImage`,
  AdminDeleteUserProfileVideo: `user/DeleteUserProfileVideo`,

  AdminStateList: `state/getAllStates`,
  AdminAddState: `state/addstate`,
  AdminStateDetails: `state/viewstate`,
  AdminEditState: `state/editstate`,
  AdminStateName: `state/getStateName`,

  AdminCityListNew: `city/getAllCityNameForMobile`,

  AdminDistrictList: `district/getAllDistricts`,
  AdminAddDistrict: `district/adddistrict`,
  AdminDistrictDetails: `district/viewdistrict`,
  AdminEditistrict: `district/editdistrict`,
  AdminDistrictName: `district/getDistrictName`,
  AdmingetDistrictByStateName: `district/getdistrictBystateName`,
  AdmingeAlltDistrictByStateName: `district/getAlldistrictBystateName`,

  AdminCityList: `city/getAllcity`,
  AdminAddCity: `city/addcity`,
  AdminCityDetails: `city/viewcity`,
  AdminEditCity: `city/editcity`,
  AdminCityName: `district/getCityName`,
  AdmingetCityBYDistrictName: `city/getCityBYDistrictName`,
  AdmingeAlltDistricByCityName: `city/getAlldistrictBycityName`,

  AdminFaqList: `faq/getAllFAQ`,
  AdminAddFaq: `faq/addFAQ`,
  AdminEditFaq: `faq/editFAQ`,
  AdminFaqDetails: `faq/viewFAQ`,
  AdminFaqDelete: `faq/deleteFAQ`,
  AdminFaqtatusUpdate: `faq/updateFAQStatus`,

  AdminBulkNotification: `bulkNotification/getAllBulkNotifications`,
  AdminGetAllStylistBulkNotifications: `StylistBulkNotification/getAllStylistBulkNotifications`,
  AdminSendStylistBulkNotifications: `StylistBulkNotification/sendStylistBulkNotifications`,

  AdminCMSList: `cms/getAllCMSPage`,
  AdminAddCMS: `cms/addCMSPage`,
  AdminCMSDetails: `cms/viewCMSPage`,
  AdminEditCMS: `cms/editCMSPage`,

  AdminCampaignList: `campaign/getAllCampaign`,
  AdminAddCampaign: `campaign/addCampaign`,
  AdminCampaignDetails: `campaign/viewCampaign`,
  AdminEditCampaign: `campaign/editCampaign`,
  AdminCampaignStatusUpdate: `campaign/updateCampaignStatus`,

  AdminBannerList: `banner/getAllBanner`,
  AdminAddBanner: `banner/createBanner`,
  AdminEditBanner: `banner/updateBanner`,
  AdminBannerDetails: `banner/getBannerDetails`,

  AdminAdminUserList: `admin/getAllAdminUsers`,
  AdminAdminEditUser: `admin/adminEditUser`,
  AdminViewAdminPermission: `admin/viewPermission`,
  AdminUserResults: `admin/getAdminUserResults`,
  AdminUpdateStatusUser: `admin/updateAdminUserStatus`,

  AdminUpdateStatusBanner: `banner/updateBannerStatus`,

  AdminServicesList: `service/getAllServices`,
  AdminServicesDetails: `service/viewService`,
  AdminSubServicesDetails: `service/viewSubService`,

  AdminAddService: `service/addService`,
  AdminEditService: `service/editService`,
  AdminUpdateStatusService: `service/updateServiceStatus`,
  AdminUpdateStatusSubService: `service/updateSubServiceStatus`,

  AdminAllSubService: `service/getAllSubServices`,
  AdminAddSubService: `service/addSubService`,
  AdminEditSubService: `service/editSubService`,

  AdminPackageList: `generatePackage/getAllPackages`,
  AdminPackageDetails: `generatePackage/getPackageDetails`,
  AdminPackageStatusUpdate: `generatePackage/updatePackageStatus`,

  AdminOfferList: `generateOffer/getAllOffers`,
  AdminOfferDetails: `generateOffer/getOfferDetails`,
  AdminOfferStatusUpdate: `generateOffer/updateOfferStatus`,
  AdminOfferUpdate: `generateOffer/editOffer`,

  AdminServicesListMobile: `service/getAllServicesForMobile`,
  AdminSubServicesListMobile: `service/getAllSubServicesForAdmin`,

  AdminGetAllUsers: `user/getAllUsers`,
  AdminGetAllJobs: `salonJob/getAllJobsForUsersNew`,
  AdminGetAllJobsCountByStatus: `salonJob/getAllJobsCountByStatus`,
  AdminSalonStatusUpdate: `salonJob/updateSalonJobStatus`,
  AdminJobDetails: `salonJob/getJobsDetails`,

  AdminAssignServices: `demo/assignService`,
  AdminAssignServicesList: `demo/getAllServiceAssign`,
  AdminUpdateStatusAssign: `demo/updateServiceAssignStatus`,
  AdminGetUserAssignService: `demo/getUserAssignService`,
  AdminUpdateAssignServiceByUser: `demo/updateAssignServiceByUser`,

  AdminGetChatParticipants: `chat/getChatParticipants`,
  AdminGetMessageListByChatId: `chat/getMessageListByChatId`,
};
