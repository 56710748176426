import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getLocalStorageItem } from "../../store/localStorage";
import { withPermissions } from "../context/PermissionsContext";

const SideBar = ({ permissions }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  var retrievedObject = getLocalStorageItem("myStylistAdmin");

  const hasPermission = (permission) => {
    return permissions.includes(permission);
  };

  const isDashboardVisible = permissions?.dashboard?.isMenuVisible;
  const isMenuManagementVisible = permissions?.menuManagement?.isMenuVisible;
  const isStylistManagementVisible =
    permissions?.stylistManagement?.isMenuVisible;
  const isOffersVisible = permissions?.offers?.isMenuVisible;
  const isPackagesVisible = permissions?.packages?.isMenuVisible;
  const isCampaignVisible = permissions?.campaign?.isMenuVisible;
  const isUserNotificationVisible =
    permissions?.userNotification?.isMenuVisible;
  const isBulkNotificationVisible =
    permissions?.bulkNotification?.isMenuVisible;
  const isCmsVisible = permissions?.cms?.isMenuVisible;
  const isFaqVisible = permissions?.faq?.isMenuVisible;
  const isBannersVisible = permissions?.banners?.isMenuVisible;
  const isStateVisible = permissions?.state?.isMenuVisible;
  const isCityVisible = permissions?.city?.isMenuVisible;
  const isAdminUserManagementVisible =
    permissions?.adminUserManagement?.isMenuVisible;

  return (
    <aside className="main-left-sidebar">
      <div className="logo mb-2">
        <Link to="#">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/logo.svg"}
            alt="Logo"
            title=""
          />
        </Link>
      </div>
      <div className="left-menu-main flex-fill">
        <h6 className="menu-title">Main</h6>
        <ul className="left-menu">
          {isDashboardVisible && (
            <NavItem
              to="/Home"
              icon="assets/img/icon-dashboard.svg"
              text="Dashboard"
              isActive={splitLocation[1] === ""}
            />
          )}
          {isAdminUserManagementVisible && (
            <NavItem
              to="/ManageAdminUsers"
              icon="assets/img/icon-services-management.svg"
              text="Admin Users"
              isActive={
                splitLocation[1] === "ManageAdminUsers" ||
                splitLocation[1] === "EditAdminUser" ||
                splitLocation[1] === "ViewPermission"
              }
            />
          )}
          {isStylistManagementVisible && (
            <NavItem
              to="/ManageExperts"
              icon="assets/img/icon-stylists-management.svg"
              text="Stylists Management"
              isActive={
                splitLocation[1] === "ManageExperts" ||
                splitLocation[1] === "EditExpert" ||
                splitLocation[1] === "updateLocation" ||
                splitLocation[1] === "updateProfile" ||
                splitLocation[1] === "amenities" ||
                splitLocation[1] === "AssignServices" ||
                splitLocation[1] === "EditAssignServices" ||
                splitLocation[1] === "UploadUserImage" ||
                splitLocation[1] === "availability"
              }
            />
          )}
          {isMenuManagementVisible && (
            <NavItem
              to="/ManageServices"
              icon="assets/img/icon-services-management.svg"
              text="Menu Management"
              isActive={
                splitLocation[1] === "ManageServices" ||
                splitLocation[1] === "editServices" ||
                splitLocation[1] === "editSubServices"
              }
            />
          )}
        </ul>

        <h6 className="menu-title">Business Management</h6>
        <ul className="left-menu">
          {" "}
          {isOffersVisible && (
            <NavItem
              to="/ManageOffers"
              icon="assets/img/icon-offer.svg"
              text="Offer"
              isActive={splitLocation[1] === "ManageOffers"}
            />
          )}{" "}
          {isPackagesVisible && (
            <NavItem
              to="/ManagePackages"
              icon="assets/img/icon-package.svg"
              text="Package"
              isActive={splitLocation[1] === "ManagePackages"}
            />
          )}
          {isCampaignVisible && (
            <NavItem
              to="/ManageCampaigns"
              icon="assets/img/icon-campaign.svg"
              text="Campaign"
              isActive={splitLocation[1] === "ManageCampaigns"}
            />
          )}
          {isBannersVisible && (
            <NavItem
              to="/ManageHomeBanners"
              icon="assets/img/icon-user-jobs.svg"
              text="Home Banners"
              isActive={splitLocation[1] === "ManageHomeBanners"}
            />
          )}
        </ul>

        <h6 className="menu-title">Notification Management</h6>
        <ul className="left-menu">
          {" "}
          {isUserNotificationVisible && (
            <NavItem
              to="/StylistBulkNotification"
              icon="assets/img/icon-push-notification.svg"
              text="User Push Notification"
              isActive={splitLocation[1] === "StylistBulkNotification"}
            />
          )}
          {isBulkNotificationVisible && (
            <NavItem
              to="/BulkNotification"
              icon="assets/img/icon-push-notification.svg"
              text="Stylist Push Notification"
              isActive={splitLocation[1] === "BulkNotification"}
            />
          )}
        </ul>

        <h6 className="menu-title">Location Management</h6>
        <ul className="left-menu">
          {" "}
          {isStateVisible && (
            <NavItem
              to="/ManageState"
              icon="assets/img/icon-push-notification.svg"
              text="State"
              isActive={splitLocation[1] === "ManageState"}
            />
          )}
          {isCityVisible && (
            <NavItem
              to="/ManageCity"
              icon="assets/img/icon-push-notification.svg"
              text="City"
              isActive={splitLocation[1] === "ManageCity"}
            />
          )}
        </ul>

        <h6 className="menu-title">FAQ’s Management</h6>
        <ul className="left-menu">
          {" "}
          {isFaqVisible && (
            <NavItem
              to="/ManageFaqs"
              icon="assets/img/icon-push-notification.svg"
              text="FAQ’S"
              isActive={splitLocation[1] === "ManageFaqs"}
            />
          )}
        </ul>

        <h6 className="menu-title">Content Management </h6>
        <ul className="left-menu">
          {" "}
          {isCmsVisible && (
            <NavItem
              to="/CMS"
              icon="assets/img/icon-push-notification.svg"
              text="CMS"
              isActive={splitLocation[1] === "CMS"}
            />
          )}
        </ul>
      </div>
    </aside>
  );
};

const NavItem = ({ to, icon, text, isActive }) => (
  <li className={`nav-item ${isActive ? "active" : ""}`}>
    <Link to={to}>
      <img src={process.env.PUBLIC_URL + `/${icon}`} alt="" title="" />
      {text}
    </Link>
  </li>
);

export default withPermissions(SideBar);
